import React from 'react';
import ReactMarkdown from 'react-markdown';

const InfoSection = ({ data: { description } }) => (
  <div className="logo-section">
    <div className="container-tight">
      <ReactMarkdown className="small-title" children={description} />
    </div>
  </div>
);

export { InfoSection };
