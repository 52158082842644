import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useSelector } from 'react-redux';

export const STATUS_BAR_QUERY = graphql`
  query StatusBarQuery {
    datoCmsUpdateBar {
      text
      colour {
        hex
      }
      label
      active
    }
  }
`;

const StatusBar = () => {
  const data = useStaticQuery(STATUS_BAR_QUERY);
  const user = useSelector((state) => state.user.data);

  const {
    datoCmsUpdateBar: {
      active,
      label,
      colour: { hex },
      text,
    },
  } = data;

  return (
    active && (
      <div className={user?.email ? "banner-username" : "banner"} style={{ backgroundColor: hex }}>
        <div className={user?.email ? "banner-wrap-username" : "banner-wrap"}>
          {user?.email ? (
            <div>
              <div>Hi {user.fullname ? user.fullname : user.username}</div>
            </div>
          ) : (
            <>
              {label && (
                <div className="content-wrap-01">
                  <div>{label.toUpperCase()}</div>
                </div>
              )}
              <div>{text}</div>
            </>
          )}
        </div>
      </div>
    )
  );
};

export { StatusBar };
