/* eslint-disable jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */

import React from 'react';
import PropTypes from 'prop-types';

import { Footer, ScrollToTop, StatusBar, TopNavigation } from './index';
import { SignupCtaSection } from '../sections';

const Layout = ({ children }) => {
  return (
    <div>
      <StatusBar />
      <TopNavigation />
      {children}
      <SignupCtaSection />
      <Footer />
      <ScrollToTop />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export { Layout };
