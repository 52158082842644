import React from 'react';

const TestimonialsSection = ({ data }) => {
  const renderTestimonial = (testimonial) => {
    return (
      <div key={testimonial.name}>
        <h3>{testimonial.name}</h3>
        <video src={testimonial.images[0].url} height={350} width="100%" preload="true" controls>
          We're sorry but your browser doesn't support video players. Update your browser or switch
          to another one that supports it.
        </video>
      </div>
    );
  };
  return (
    <div className="testimonials">{data.map((testimonial) => renderTestimonial(testimonial))}</div>
  );
};

export { TestimonialsSection };
