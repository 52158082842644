import React from 'react';
import { CollapsibleTopicBlock } from '../components';
import { useSelector, useDispatch } from 'react-redux';

import DocumentIcon from '../assets/icons/pdf2.svg';
import { addToCart } from '../store/cart';

const TopicSection = ({ data: { topics, documents } }) => {
    const dispatch = useDispatch();
    const miniCart = useSelector((state) => new Set(state.cart.miniCart));
    const ownedProducts = useSelector((state) => new Set(state.cart.ownedProducts));
    const user = useSelector((state) => state.user.data);

    const featuredDocuments = documents.filter(({node}) => node.isFeatured)

    topics.sort((a, b) => {
        return a.node.name.localeCompare(b.node.name)
    })

    function mainButtonClick(id) {
        dispatch(addToCart(id, user));
    }

    const slugify = (text) =>
    text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text

    const renderFeaturedDocuments = ({node : document}) => {

        let documentState = "buy"

        if (miniCart.has(parseInt(document.originalId))) {
            documentState = 'in cart';
        }

        if (ownedProducts.has(document.originalId)) {
            documentState = 'Purchased';
        }
        const freeDocument = document.freeSample || document.freeDocument;

        const fileUrl = freeDocument
            ? `${process.env.GATSBY_BACKEND_URL}/api/download/topics/${document.name}?id=${document.originalId}`
            : `${process.env.GATSBY_URL}/modules/${document.module.slug || ''}/${document.documentCategory[0]?.slug || ''}/${encodeURIComponent(slugify(document.name))}`

        return (
            <li className="category-option" key={document.originalId}>
            <img src={DocumentIcon} className="category-option-image" alt="Category option" />
            <div className="category-option-text">
                <a
                href={fileUrl}
                className="category-option-link button-text"
                >
                {document.name}
                </a>
                <span className="category-option-info" style={{marginTop: '7px', display: 'flex', alignItems: 'center'}}>
                    {freeDocument && <div className="badge free-sample" style={{ marginLeft : "0px", marginRight : "7px", top: "0px"}}>FREE SAMPLE</div>}
                    {!freeDocument && document.hasPassword && (
                    <>
                            <span className="badge premium" style={{ marginLeft : "0px", top: "0px"}}>£{document.price || '20.00'}</span>
                            <button
                                style={{ marginLeft : "0px", marginRight : "7px", top: "0px"}}
                                className="button-b badge free-sample"
                                onClick={() => {
                                    documentState === 'buy' ? mainButtonClick(document.originalId)
                                    : documentState === 'Purchased'
                                    ? navigate('/profile')
                                    : navigate('/cart')
                                }}
                            >
                            ({documentState})
                            </button>
                    </>
                    )}
                <span>PDF</span>
                </span>
            </div>
            {/* {document.hasPassword && <img src={LockIcon} className="lock-icon" alt="Locked" />} */}
            </li>
        );
    };

    return (    
        <div id="FAQ">
            {featuredDocuments?.length > 0 
                && (<div style={{ margin : "60px 10px 40px 10px"}}>
                    <div className="category-breadcrumb-heading">
                        <h3>Featured documents:</h3>
                    </div>
                    <ul className="category-options">{featuredDocuments?.map(renderFeaturedDocuments)}</ul> 
                </div>)
            }
            <div className="container">
                <div className="w-layout-grid faq-grid">
                    {topics.map((topic) => {
                        let topicData = [];
                        topicData.title = topic.node.name;
                        topicData.documents = documents.filter((document) => {
                            for (let i = 0; i < document.node.documentTopic.length; i++) {
                                if (document.node.documentTopic[i].id === topic.node.id) {
                                    return true;
                                }
                            }
                        });

                        return (
                            <CollapsibleTopicBlock
                                data={topicData}
                                key={topic.node.id}
                                miniCart={miniCart}
                                ownedProducts={ownedProducts}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export { TopicSection };
