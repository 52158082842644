import React from 'react';

const FeaturedDocuments = ({ data }) => {
  const renderCoverImage = module => {
    let url = '';
    switch (module) {
      case 'a-level':
        url = '/a-level.png';
        break;
      case '11-plus':
        url = '/11-plus.png';
        break;
      case 'ap-calculus':
        url = '/ap-calculus.png';
        break;
      case 'ap-statistics':
        url = '/ap-statistics.png';
        break;
      case 'gcse-igcse-o-level':
        url = '/gcse.png';
        break;
      case 'ib':
        url = '/ib.png';
        break;
      case 'university':
        url = '/university.png';
        break;
      default:
        url = '/a-level.png';
    }
    return (
      <img src={url} />
    );
  }
  const renderCoverPage = document => {
    const module = document.module.slug;
    const freeDocument = document.freeSample || document.freeDocument;
    const cleanFileUrl = document.fileUrl.includes('https://mymathscloud.ams3.digitaloceanspaces.com/')
      ? unescape(document.fileUrl.replace('https://mymathscloud.ams3.digitaloceanspaces.com/', ''))
      : document.fileUrl;
    const documentId = document.id.replace('DatoCmsDocument-', '').replace('-en', '');
    const fileUrl = `https://mymathscloud.com/api/download/modules/${cleanFileUrl}?id=${documentId}`;
    return (
      <div
        key={document.id}
        className="scolumn 3 x-centered"
        onClick={
          e => freeDocument ?
            window.location.href = fileUrl
          :
            window.location.href = `/modules/${module}/${document.documentCategory.map(c => c.slug).join('/')}/${encodeURIComponent(slugify(document.name))}`
        }
      >
        <div className="featured-documents">
          {renderCoverImage(module)}
        </div>
        <div className="document-name category-option-link button-text">
          {document.name}
        </div>
        <span className="category-option-info">
            {document.freeSample && <span className="badge free-sample">FREE SAMPLE</span>}
            {!document.freeSample && !document.freeDocument && document.hasPassword && (
              <span className="badge premium">
                £{document.price || '20.00'}
              </span>
            )}
            PDF
          </span>
      </div>
    );
  }
  const slugify = text => text.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');            // Trim - from end of text

  return (
    <div className="featured-documents">
      <p className="m-0 bold 1.5">Featured Documents</p>
      <div className="srow container-flex mx-auto cursor-pointer">
        {data.map(({ node: document }) => renderCoverPage(document))}
      </div>
    </div>
  );
};

export { FeaturedDocuments };
